<template>
  <c-box>
    <c-flex
      width="100%"
      align-items="center"
      justify-content="space-between"
      margin-bottom="16px"
    >
      <c-button
        variant="ghost"
        width="30px"
        min-width="30px"
        height="30px"
        margin="0"
        padding="0"
        margin-right="16px"
        @click="onClickBackToPreview"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>
      <c-text
        color="neutral.superDarkGray"
        font-size="22px"
        font-weight="600"
        margin-right="auto"
      >
        Target & Saran
      </c-text>
      <c-tooltip
        :label="iconCloud.text"
        placement="bottom"
      >
        <c-box
          :width="['35px']"
          :height="['35px']"
          margin-right="16px"
          :class="saveState === null ? 'animate-spin' : ''"
        >
          <inline-svg
            :src="iconCloud.icon"
            height="100%"
            width="100%"
            fill="#008C81"
          />
        </c-box>
      </c-tooltip>
      <BaseButton
        :left-svg-icon="require('@/assets/icons/icon-save.svg')"
        left-svg-icon-color="white"
        :disabled="!isValidAllFormProfilGizi || saveState === null || isLoadingSave"
        @click="onSave"
      >
        Simpan
      </BaseButton>
    </c-flex>

    <NutritionalProfileSteps
      :draft="draft"
      :target-and-suggestions="$v?.$invalid ? -1 : 1"
      :is-loading="isLoadingSave || saveState === null"
      @change:step="(step) => handleChangeStep(step, true)"
    />
    <c-box>
      <FormBaseEditor
        v-model="form.primaryNutritionDiagnosis"
        type="textarea"
        label="Diagnosis Gizi Primer"
        placeholder="Masukkan diagnosis gizi primer"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.primaryNutritionDiagnosis)"
        invalid-text="Wajib diisi"
        @blur="$v.form.primaryNutritionDiagnosis.$touch"
      />

      <FormBaseEditor
        v-model="form.secondaryNutritionDiagnosis"
        type="textarea"
        label="Diagnosis Gizi Sekunder"
        placeholder="Masukkan diagnosis gizi sekunder"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.secondaryNutritionDiagnosis)"
        invalid-text="Wajib diisi"
        @blur="$v.form.secondaryNutritionDiagnosis.$touch"
      />

      <FormBaseEditor
        v-model="form.nutritionalIntervention"
        type="textarea"
        label="Intervensi Gizi"
        placeholder="Masukkan intervensi gizi"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.nutritionalIntervention)"
        invalid-text="Wajib diisi"
        @blur="$v.form.nutritionalIntervention.$touch"
      />

      <FormBaseEditor
        v-model="form.mealPlanGoals"
        type="textarea"
        label="Tujuan Meal Plan"
        placeholder="Masukkan tujuan mealplan"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.mealPlanGoals)"
        invalid-text="Wajib diisi"
        @blur="$v.form.mealPlanGoals.$touch"
      />

      <FormBaseEditor
        v-model="form.thingsThatNeedToBeMaintained"
        type="textarea"
        label="Hal yang Perlu Dipertahankan"
        placeholder="Masukkan hal yang perlu dipertahankan"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.thingsThatNeedToBeMaintained)"
        invalid-text="Wajib diisi"
        @blur="$v.form.thingsThatNeedToBeMaintained.$touch"
      />

      <FormBaseEditor
        v-model="form.thingsThatNeedToBeImproved"
        type="textarea"
        label="Hal yang Perlu diperbaiki"
        placeholder="Masukkan hal yang perlu diperbaiki"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.thingsThatNeedToBeImproved)"
        invalid-text="Wajib diisi"
        @blur="$v.form.thingsThatNeedToBeImproved.$touch"
      />

      <FormBaseEditor
        v-model="form.targetForThisPeriod"
        type="textarea"
        label="Target Periode Ini"
        placeholder="Masukkan target periode ini"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.targetForThisPeriod)"
        invalid-text="Wajib diisi"
        @blur="$v.form.targetForThisPeriod.$touch"
      />

      <FormBaseEditor
        v-model="form.nutritionAndLifestyleAdvice"
        type="textarea"
        label="Saran Gizi dan Gaya Hidup"
        placeholder="Masukkan saran gizi dan gaya hidup"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.nutritionAndLifestyleAdvice)"
        invalid-text="Wajib diisi"
        @blur="$v.form.nutritionAndLifestyleAdvice.$touch"
      />

      <c-grid
        w="100%"
        :template-columns="['repeat(2, 1fr)']"
        :gap="['16px']"
        margin-top="24px"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="onOpenModalCancel"
        >
          Sebelumnya
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="onSave"
        >
          Simpan
        </c-button>
      </c-grid>

      <BaseModal
        :is-open="!!isOpenModalCancel"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 16px 20px 16px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
              margin-bottom="8px"
            >
              Data kamu belum lengkap
            </BaseText>
            <BaseText
              size-mobile="12px"
              size-desktop="14px"
              color="neutral.darkGray"
            >
              Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCloseModalCancel"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onConfirmModalCancel"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>

    <BaseModal
      :is-open="!!isOpenModalNext"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 16px 20px 16px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="8px"
          >
            Data kamu belum lengkap
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px"
            color="neutral.darkGray"
          >
            Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="onCancelNext"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="onStepTo"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- MODAL -->
    <ModalSuccessSaveData
      :is-open="isOpenModalSuccessSaveData"
      @close="isOpenModalSuccessSaveData = false"
    />

    <!-- Modal back to preview -->
    <BaseModal
      :is-open="isOpenModalBackToPreview"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            Apakah anda yakin<br>ingin membatalkan pengisian formulir?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="isOpenModalBackToPreview = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackToPreview"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-warning.svg')"
      title="Gagal Menyimpan Data"
      description="Terdapat kesalahan data, coba tinjau ulang datamu"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import _ from 'lodash'
import {
  proteinRequirementsperkgBodyWeightOptions,
  fatRequirementsOptions,
  fiberNeedsForAgeCategoryOptions, tabs,
} from '@/constants/meal-plans'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import NutritionalProfileSteps from '@/components/meal-plan/_widgets/steps.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { CBox, CButton, CFlex, CGrid, CImage, CText } from '@chakra-ui/vue'
import { required } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import ModalSuccessSaveData
  from '@/components/meal-plan/_widgets/modal-success-save-data.vue'
import EventBus from '@/utils/event-bus'
import FormBaseEditor from '@/views/client/kuisioner/forms/form-base-editor.vue'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FormTargetAndSuggestion',
  components: {
    FormBaseEditor,
    ModalSuccessSaveData,
    CBox,
    CFlex,
    CImage,
    CButton,
    CText,
    CGrid,
    BaseModal,
    BaseText,
    BaseButton,
    NutritionalProfileSteps,
    ModalSimpleInfo,
  },
  props: {
    dataPreview: {
      type: Object,
      default: () => ({}),
    },
    draft: {
      type: [Object, null],
      default: null,
    },
    isDraftExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      isOpenModalBackToPreview: false,
      isOpenModalNext: false,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      formDuplicate: {},
      toggle: true,
      form: {
        primaryNutritionDiagnosis: null,
        secondaryNutritionDiagnosis: null,
        nutritionalIntervention: null,
        mealPlanGoals: null,
        thingsThatNeedToBeMaintained: '',
        thingsThatNeedToBeImproved: '',
        targetForThisPeriod: '',
        nutritionAndLifestyleAdvice: '',
      },
      isLoadingSave: false,
      isLoadingData: true,
      isOpenModalCancel: false,
      stepTo: {
        tab: '',
        savePosition: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      saveState: 'general/saveState',
    }),
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
          }
      }
    },
    isSaveDisabled() {
      if (!this.hasChanges) {
        return true
      }
      return this.isLoadingSave
    },
    hasChanges() {
      return !_.isEqual(this.form, this.formDuplicate)
    },
    constants() {
      return {
        proteinRequirementsperkgBodyWeightOptions,
        fatRequirementsOptions,
        fiberNeedsForAgeCategoryOptions,
        tabs,
      }
    },
    isValidAllFormProfilGizi() {
      const isAllValid = isAllFormValid({
        nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
        nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
        bodyConditions: this.draft?.bodyConditions?.validation === 1,
        targetAndSuggestions: !this.$v?.$invalid,
      })
      return isAllValid
    },
  },
  watch: {
    hasChanges: {
      handler: _.debounce(function(val) {
        if (val) {
          this.formDuplicate = _.cloneDeep(this.form)
          this.onSaveDraft()
        } else if (this.saveState === null) {
          this.setSaveState(true)
        }
      }, 1000),
    },
    form: {
      handler() {
        this.setSaveState(null)
        const data = {
          form: 'target-and-suggestion',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.draft?.targetAndSuggestions && !_.isEmpty(this.draft?.targetAndSuggestions)) {
      this.form = this.draft?.targetAndSuggestions
      this.form.thingsThatNeedToBeMaintained = Array.isArray(this.draft?.targetAndSuggestions?.thingsThatNeedToBeMaintained) ? this.draft?.targetAndSuggestions?.thingsThatNeedToBeMaintained?.join(', ') : this.draft?.targetAndSuggestions?.thingsThatNeedToBeMaintained
      this.form.thingsThatNeedToBeImproved = Array.isArray(this.draft?.targetAndSuggestions?.thingsThatNeedToBeImproved) ? this.draft?.targetAndSuggestions?.thingsThatNeedToBeImproved?.join(', ') : this.draft?.targetAndSuggestions?.thingsThatNeedToBeImproved
      this.form.targetForThisPeriod = Array.isArray(this.draft?.targetAndSuggestions?.targetForThisPeriod) ? this.draft?.targetAndSuggestions?.targetForThisPeriod?.join(', ') :this.draft?.targetAndSuggestions?.targetForThisPeriod
      this.form.nutritionAndLifestyleAdvice = Array.isArray(this.draft?.targetAndSuggestions?.nutritionAndLifestyleAdvice) ? this.draft?.targetAndSuggestions?.nutritionAndLifestyleAdvice?.join(', ') : this.draft?.targetAndSuggestions?.nutritionAndLifestyleAdvice

      this.$v.$touch()
    }

    this.formDuplicate = _.cloneDeep(this.form)
    setTimeout(() => {
      this.isLoadingData = false
    }, 1500)
  },
  methods: {
    ...mapMutations({
      setSaveState: 'general/setSaveState',
    }),
    isAllFormValid,
    isInvalidField,
    parseErrors,
    onClickBackToPreview() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalBackToPreview = true
        return
      }
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    handleBackToPreview() {
      this.isOpenModalBackToPreview = false
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    onOpenModalCancel() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalCancel = true
        return
      }
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['body-condition'],
        },
      })
    },
    onCloseModalCancel() {
      this.isOpenModalCancel = false
    },
    onConfirmModalCancel() {
      this.onCloseModalCancel()
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['body-condition'],
        },
      })
    },
    async onSave() {
      try {
        await this.onSaveDraft(true)
      } catch (err) {
        this.isOpenModalFailedSaveData = true
      }
    },
    async onSaveDraft(confirm = null) {
      try {
        this.isLoadingSave = true
        this.setSaveState(null)

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: Number(this.$route.params.month),
          targetAndSuggestions: {
            ...this.form,
            validation: this.$v.$invalid ? -1 : 1,
          },
        }

        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })
        this.$v.$touch()

        if (!isAllFormValid({
          nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
          nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
          bodyConditions: this.draft?.bodyConditions?.validation === 1,
          targetAndSuggestions: !this.$v?.$invalid,
        })) {
          EventBus.$emit('TRIGGER_SAVE_MEAL_PLAN', Date.now())
        }
        // this.formDuplicate = _.cloneDeep(this.form)
        this.setSaveState(true)
        if (confirm) {
          this.isOpenModalSuccessSaveData = true
        }
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
    handleChangeStep(tab, savePosition) {
      this.$v.$touch()
      this.stepTo.tab = tab
      this.stepTo.savePosition = savePosition
      if (this.$v.$invalid) {
        this.isOpenModalNext = true
        return
      }
      this.onStepTo()
    },
    onCancelNext() {
      this.isOpenModalNext = false
    },
    async onStepTo() {
      try {
        await this.onSaveDraft()
        if (this.stepTo) {
          this.$router.replace({
            name: 'nutri.meal-plan.profile.edit',
            params: {
              ...this.$route.params,
              savePosition: this.stepTo.savePosition,
            },
            query: {
              tab: this.stepTo.tab,
            },
          })
        }
      } catch (e) {
        //
      }
    },
  },
  validations() {
    return {
      form: {
        primaryNutritionDiagnosis: {
          required,
        },
        secondaryNutritionDiagnosis: {
          required,
        },
        nutritionalIntervention: {
          required,
        },
        mealPlanGoals: {
          required,
        },
        thingsThatNeedToBeMaintained: {
          required,
        },
        thingsThatNeedToBeImproved: {
          required,
        },
        targetForThisPeriod: {
          required,
        },
        nutritionAndLifestyleAdvice: {
          required,
        },
      },
    }
  },
}
</script>
