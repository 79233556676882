var render = function () {
  var _vm$$v;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": _vm.onClickBackToPreview
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Target & Saran ")]), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.saveState === null ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllFormProfilGizi || _vm.saveState === null || _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _c('NutritionalProfileSteps', {
    attrs: {
      "draft": _vm.draft,
      "target-and-suggestions": (_vm$$v = _vm.$v) !== null && _vm$$v !== void 0 && _vm$$v.$invalid ? -1 : 1,
      "is-loading": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "change:step": function changeStep(step) {
        return _vm.handleChangeStep(step, true);
      }
    }
  }), _c('c-box', [_c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Diagnosis Gizi Primer",
      "placeholder": "Masukkan diagnosis gizi primer",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.primaryNutritionDiagnosis),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.primaryNutritionDiagnosis.$touch
    },
    model: {
      value: _vm.form.primaryNutritionDiagnosis,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "primaryNutritionDiagnosis", $$v);
      },
      expression: "form.primaryNutritionDiagnosis"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Diagnosis Gizi Sekunder",
      "placeholder": "Masukkan diagnosis gizi sekunder",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.secondaryNutritionDiagnosis),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.secondaryNutritionDiagnosis.$touch
    },
    model: {
      value: _vm.form.secondaryNutritionDiagnosis,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "secondaryNutritionDiagnosis", $$v);
      },
      expression: "form.secondaryNutritionDiagnosis"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Intervensi Gizi",
      "placeholder": "Masukkan intervensi gizi",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.nutritionalIntervention),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.nutritionalIntervention.$touch
    },
    model: {
      value: _vm.form.nutritionalIntervention,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nutritionalIntervention", $$v);
      },
      expression: "form.nutritionalIntervention"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Tujuan Meal Plan",
      "placeholder": "Masukkan tujuan mealplan",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mealPlanGoals),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.mealPlanGoals.$touch
    },
    model: {
      value: _vm.form.mealPlanGoals,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mealPlanGoals", $$v);
      },
      expression: "form.mealPlanGoals"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Hal yang Perlu Dipertahankan",
      "placeholder": "Masukkan hal yang perlu dipertahankan",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.thingsThatNeedToBeMaintained),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.thingsThatNeedToBeMaintained.$touch
    },
    model: {
      value: _vm.form.thingsThatNeedToBeMaintained,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thingsThatNeedToBeMaintained", $$v);
      },
      expression: "form.thingsThatNeedToBeMaintained"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Hal yang Perlu diperbaiki",
      "placeholder": "Masukkan hal yang perlu diperbaiki",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.thingsThatNeedToBeImproved),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.thingsThatNeedToBeImproved.$touch
    },
    model: {
      value: _vm.form.thingsThatNeedToBeImproved,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "thingsThatNeedToBeImproved", $$v);
      },
      expression: "form.thingsThatNeedToBeImproved"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Target Periode Ini",
      "placeholder": "Masukkan target periode ini",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.targetForThisPeriod),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.targetForThisPeriod.$touch
    },
    model: {
      value: _vm.form.targetForThisPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "targetForThisPeriod", $$v);
      },
      expression: "form.targetForThisPeriod"
    }
  }), _c('FormBaseEditor', {
    attrs: {
      "type": "textarea",
      "label": "Saran Gizi dan Gaya Hidup",
      "placeholder": "Masukkan saran gizi dan gaya hidup",
      "full-width": "",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.nutritionAndLifestyleAdvice),
      "invalid-text": "Wajib diisi"
    },
    on: {
      "blur": _vm.$v.form.nutritionAndLifestyleAdvice.$touch
    },
    model: {
      value: _vm.form.nutritionAndLifestyleAdvice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nutritionAndLifestyleAdvice", $$v);
      },
      expression: "form.nutritionAndLifestyleAdvice"
    }
  }), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px'],
      "margin-top": "24px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "click": _vm.onOpenModalCancel
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalCancel,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCloseModalCancel
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onConfirmModalCancel
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalNext,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCancelNext
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onStepTo
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSuccessSaveData', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalSuccessSaveData = false;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalBackToPreview,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin"), _c('br'), _vm._v("ingin membatalkan pengisian formulir? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalBackToPreview = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleBackToPreview
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-warning.svg'),
      "title": "Gagal Menyimpan Data",
      "description": "Terdapat kesalahan data, coba tinjau ulang datamu"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }