<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['12px', '16px']"
    :is-read-only="isReadOnly"
    :w="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-show="!isWithoutLabel"
      :font-size="['14px', '16px']"
      :color="isDisabled ? '#888' : (isInvalid ? '#D32737' : (value ? '#008C81' : '#555'))"
      font-weight="400"
      font-family="Roboto"
      :opacity="isDisabled ? '1' : ''"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <BaseEditor
      v-model="modelValue"
      image-provider-url="/v1/admin/meal-plans/upload"
      :disabled="isDisabled || isReadOnly"
    />
    <c-form-helper-text
      v-if="isHelper && helperText"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'
import BaseEditor from '@/components/elements/base-editor.vue'

export default {
  name: 'FormBaseEditor',
  components: {
    BaseEditor,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    isWithoutLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'right-element',
  ],
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      if (this.helperTextType === 'info') {
        return require('@/assets/icons/icon-info-2.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
  },
}
</script>

<style scoped>
::v-deep .default {
  cursor: pointer;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border: 1px solid rgba(136, 136, 136, 0.2);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 0 20px;
  color: #008C81;
}

@media (min-width: 768px) {
  ::v-deep .default {
    font-size: 18px;
  }
}

::v-deep .default:focus-visible {
  outline: none;
}

::v-deep .err {
  outline: 2px solid #e66673;
  color: #D32737;
}

::v-deep .err:focus-visible {
  outline: 2px solid #e66673;
}

::v-deep input,
::v-deep textarea{
  border-color: #888;
  box-shadow: 0 0 0 1px #888;
}

::v-deep input[disabled],
::v-deep textarea[disabled]{
  opacity: 1 !important;
  background-color: #F2F2F2 !important;
  color: #888 !important;
  border-color: #888 !important;
  box-shadow: 0 0 0 1px #888 !important;
  cursor: not-allowed;
}

::v-deep .disabled .mx-icon-calendar {
  cursor: not-allowed;
}

::v-deep .mx-datepicker, ::v-deep .mx-input-wrapper {
  width: 100%;
  height: 100%;
}

::v-deep .inline-table {
  display: inline-table !important;
}
</style>
